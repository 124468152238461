import { User } from 'firebase/auth';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ReduxState } from '../../Redux/ReduxInterface';
import Container from '@mui/material/Container';
import Toolbar from '@mui/material/Toolbar';
import Box from '@mui/material/Box';
import UserInformation from './UserInformation';
import ModeMenu from './ModeMenu';
import { TabSelectorActions } from '../../Redux/Actions/TabSelector';
import Typography from '@mui/material/Typography';
import { LibrarySearchActions } from '../../Redux/Actions/LibrarySearch';
import { useFlags } from 'flagsmith/react';
import { useTheme } from '@mui/material/styles';
import CustomTextFieldWithIcon from '../CustomTextFieldWithIcon/CustomTextFieldWithIcon';
import { Search } from '@mui/icons-material';
import SearchBar from '../../Screens/Studio/Dashboard/SearchBar';
import { t } from 'i18next';
import DashverseBar from './DashverseBar';
import CreateNewDashtoon from '../../Screens/Studio/Home/components/CreateNewDashtoon';
import { DashverseTab, TabName, useHomeScreenTab } from '../../Hooks/useHomeScreenTab';
import useCreateNewDashtoon from '../../Hooks/useCreateNewDashtoon';
import LoadingScreen from '../LoadingScreen/LoadingScreen';
import usePhoneLayout from '../../Hooks/usePhoneLayout';
import { ReactComponent as MenuIcon } from '../../Assets/Icons/Menu.svg';
import { useState } from 'react';
import { Drawer } from '@mui/material';
import LayoutSidebarV2 from '../LayoutSidebar/LayoutSidebarV2';
import DashverseHeader from './DashverseBarComponents/DashverseHeader';

interface AppToolBarProps {
  user: User | null;
  anchorElUser: HTMLElement | null;
  isCanvas: boolean;
  filter: boolean;
  search: string;
  handleOpenTopUpModal: () => void;
  handleOpenUserMenu: (event: React.MouseEvent<HTMLElement>) => void;
  handleCloseUserMenu: () => void;
  handleLogout: () => void;
  handleSearch: (e: any) => void;
}

const AppToolBar: React.FC<AppToolBarProps> = ({
  user,
  anchorElUser,
  isCanvas,
  filter = false,
  search = '',
  handleOpenTopUpModal,
  handleOpenUserMenu,
  handleCloseUserMenu,
  handleLogout,
  handleSearch = () => {},
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const theme = useTheme();
  const mode = useSelector((state: ReduxState) => state.theme.mode);
  const { currentTab } = useHomeScreenTab();
  const isDashverse = currentTab.id === DashverseTab.id;

  const searchQuery = useSelector((state: ReduxState) => state.librarySearch.searchQuery);

  const flags = useFlags(['image_library_feature']);
  const { loading, handleCreateNewShow } = useCreateNewDashtoon();
  const { isSmallScreen } = usePhoneLayout();

  const [drawerOpen, setDrawerOpen] = useState(false);

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };
  const handleSearchByPrompt = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch({
      type: LibrarySearchActions.UPDATE_LIBRARY_SEARCH_QUERY,
      payload: event.target.value,
    });
  };

  const handleSearchLibrary = () => {
    dispatch({
      type: TabSelectorActions.UPDATE_LIBRARY_SEARCH_TAB,
      payload: true,
    });
  };

  return (
    <Container
      sx={{
        height: '4rem',
        maxWidth: 'inherit !important',
        padding: '16px 24px 0px 24px',
      }}
    >
      {loading && <LoadingScreen />}
      <Toolbar
        disableGutters
        sx={{
          height: '4rem !important',
          minHeight: '4rem !important',
          display: 'flex',
          justifyContent: 'space-between',
          width: '100%',
        }}
      >
        {isCanvas && <div style={{ width: '40%' }}></div>}

        {isCanvas &&
          !isDashverse &&
          flags.image_library_feature?.enabled &&
          flags.image_library_feature?.value === true && (
            <Box
              style={{
                height: '36px',
                display: 'flex',
                alignItems: 'center',
                marginRight: '12px',
                marginLeft: '200px',
                marginTop: '-35px',
              }}
            >
              <Typography
                textAlign="center"
                style={{ width: '300px', height: '24px', color: theme.palette.text.primary }}
              >
                <CustomTextFieldWithIcon
                  id={'search'}
                  label=""
                  inputProps={{
                    value: searchQuery ?? '',
                  }}
                  placeholder={t('Search for Image')}
                  multiline={false}
                  onChange={handleSearchByPrompt}
                  onKeyDown={event => {
                    if (event.keyCode === 13) {
                      handleSearchLibrary();
                    }
                  }}
                  icon={<Search sx={{ fontSize: '20px' }} />}
                  role="search"
                />
              </Typography>
            </Box>
          )}
        {!isCanvas && !isDashverse && filter && (
          <>
            <Box
              sx={{
                marginLeft: isSmallScreen ? 0 : 32,
                flexGrow: 1,
                display: 'flex',
                color: theme.palette.object.primary,
                gap: '8px',
                alignItems: 'center',
                fontSize: '20px',
                fontWeight: 600,
              }}
            >
              {isSmallScreen && (
                <div>
                  <div onClick={toggleDrawer}>
                    <MenuIcon
                      color={theme.palette.object.primary}
                      style={{ width: 32, height: 32, display: 'flex', alignItems: 'center' }}
                    />
                  </div>
                  <Drawer
                    open={drawerOpen}
                    style={{ width: '300px' }}
                    onClose={() => setDrawerOpen(false)}
                    anchor={'left'}
                  >
                    <LayoutSidebarV2 />
                  </Drawer>
                </div>
              )}
              <div style={{ width: 24, height: 24, display: 'flex', alignItems: 'center' }}>
                <currentTab.icon />
              </div>
              {t(currentTab.display)}
              {!isSmallScreen && (
                <div style={{ marginLeft: '8px' }}>
                  <CreateNewDashtoon
                    isButton={true}
                    handleCreateNewShow={() => handleCreateNewShow('appToolBar')}
                  />
                </div>
              )}
            </Box>
            {currentTab.id === TabName.MY_SHOWS && !isSmallScreen && (
              <SearchBar search={search} handleSearch={handleSearch}></SearchBar>
            )}
          </>
        )}

        {/*<Box sx={{ flexGrow: 1, display: 'flex' }}></Box>*/}
        {/* <SearchBar search={search} handleSearch={handleSearch}></SearchBar> */}

        {isDashverse && <DashverseHeader />}
        <UserInformation
          user={user}
          anchorElUser={anchorElUser}
          transparentBackground={true}
          handleOpenTopUpModal={handleOpenTopUpModal}
          handleOpenUserMenu={handleOpenUserMenu}
          handleCloseUserMenu={handleCloseUserMenu}
          handleLogout={handleLogout}
          isCanvas={isCanvas}
        />
      </Toolbar>
    </Container>
  );
};

export default AppToolBar;
