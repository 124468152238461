import { SaveImageType } from '../../../../../Models/SaveImage';
import { DashCharacterTypes } from '../../../../../Models/Character';

export const calculateCreditsRequiredForCharacters = (type: DashCharacterTypes) => {
  switch (type) {
    case DashCharacterTypes.PROMPT: {
      return creditsUsage.characterCreationPrompt;
    }
    case DashCharacterTypes.TRAINED: {
      return creditsUsage.characterCreation;
    }
    default:
      throw new Error('Unknown SaveImageType');
  }
};
export const calculateCreditsRequired = (type: SaveImageType) => {
  switch (type) {
    case SaveImageType.DALLE_RESULT:
      return creditsUsage.compositionalModelInference;
    case SaveImageType.BACKGROUND_REMOVE_RESULT:
      return creditsUsage.removeBackground;

    case SaveImageType.CREATIVE_UPSCALE_RESULT:
      return creditsUsage.creativeUpscaler;

    case SaveImageType.UPSCALE_RESULT:
      return creditsUsage.simpleUpscaler;

    case SaveImageType.LIVE_RESULT:
      return creditsUsage.live;

    case SaveImageType.TXT2IMG_RESULT:
    case SaveImageType.IMG2IMG_RESULT:
    case SaveImageType.INPAINT_RESULT:
      return creditsUsage.styleInferenceWithCharacterS2C;

    default:
      console.info(`${type} type credits was requested. Returning 0`);
      return 0;
  }
};
const creditsUsage = {
  compositionalModelInference: 1,
  styleInferenceWithCharacterS2C: 1,
  creativeUpscaler: 3,
  simpleUpscaler: 3,
  removeBackground: 0,
  segment: 0,
  live: 1,
  magicEraser: 0,
  characterCreation: 50,
  characterCreationPrompt: 0,
};

export default creditsUsage;
