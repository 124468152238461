import { styled } from '@mui/material/styles';

export const Text10R = styled('div')<{ color?: string }>(({ theme, color }) => ({
  fontSize: '10px',
  fontWeight: 400,
  lineHeight: '14px',
  color: color ?? theme.palette.text.primary,
}));

export const Text10SB = styled('div')<{ color?: string }>(({ theme, color }) => ({
  fontSize: '10px',
  fontWeight: 600,
  lineHeight: '14px',
  color: color ?? theme.palette.text.primary,
}));

export const Text12R = styled('div')<{ color?: string; styles?: React.CSSProperties }>(
  ({ theme, color, styles }) => ({
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '16px',
    color: color ?? theme.palette.text.primary,
    ...styles,
  })
);

export const Text12SB = styled('div')<{ color?: string; styles?: React.CSSProperties }>(
  ({ theme, color, styles }) => ({
    fontSize: '12px',
    fontWeight: 600,
    lineHeight: '16px',
    color: color ?? theme.palette.text.primary,
    ...styles,
  })
);

export const Text14R = styled('div')<{ color?: string; style?: React.CSSProperties }>(
  ({ theme, color, style }) => ({
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '20px',
    color: color ?? theme.palette.text.primary,
    ...style,
  })
);

export const Text14SSB = styled('div')<{ color?: string; style?: React.CSSProperties }>(
  ({ theme, color, style }) => ({
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '20px',
    color: color ?? theme.palette.text.primary,
    ...style,
  })
);

export const Text14SB = styled('div')<{ color?: string; style?: React.CSSProperties }>(
  ({ theme, color, style }) => ({
    fontSize: '14px',
    fontWeight: 600,
    lineHeight: '20px',
    color: color ?? theme.palette.text.primary,
    ...style,
  })
);

export const Text16R = styled('div')<{ color?: string }>(({ theme, color }) => ({
  fontSize: '16px',
  fontWeight: 400,
  lineHeight: '24px',
  color: color ?? theme.palette.text.primary,
}));

export const Text16SB = styled('div')<{ color?: string }>(({ theme, color }) => ({
  fontSize: '16px',
  fontWeight: 600,
  lineHeight: '24px',
  color: color ?? theme.palette.text.primary,
}));

export const Text20SB = styled('div')<{ color?: string }>(({ theme, color }) => ({
  fontSize: '20px',
  fontWeight: 600,
  lineHeight: '28px',
  color: color ?? theme.palette.text.primary,
}));

export const Text24SB = styled('div')<{ color?: string }>(({ theme, color }) => ({
  fontSize: '24px',
  fontWeight: 600,
  lineHeight: '32px',
  color: color ?? theme.palette.text.primary,
}));

export const Text32SB = styled('div')<{ color?: string }>(({ theme, color }) => ({
  fontSize: '32px',
  fontWeight: 600,
  lineHeight: '36px',
  color: color ?? theme.palette.text.primary,
}));

export const Text40SB = styled('div')<{ color?: string }>(({ theme, color }) => ({
  fontSize: '40px',
  fontWeight: 600,
  lineHeight: '48px',
  color: color ?? theme.palette.text.primary,
}));
