import { config } from '../Config/Config';
import { auth } from '../firebaseConfig';
import { initiateTiming, trackEvent, trackTimedEvent } from './Analytics';
import { TimedTrackingEvents } from '../Constants/TimedTrackingEvents';
import { generateUUID } from './DashAnalytics';
import { ErrorActions } from '../Redux/Actions/Error';
import { store } from '../Redux/Store';
import { TrackingEvents } from '../Constants/TrackingEvents';

export class APIError extends Error {
  status: number;

  constructor(message: string, status: number) {
    super(message);
    this.status = status;
    this.name = 'APIError';
  }
}
export const getPathWithPlaceholders = (path: string) => {
  // Define a mapping of known prefixes to their placeholders
  const placeholders: { [key: string]: string } = {
    SHO: '{showId}',
    EPI: '{episodeId}',
    CHA: '{characterId}',
    // Add more mappings as needed
  };

  const regex = /users\/([^\/]+)\/metadata/;
  if (regex.test(path)) {
    return path.replace(regex, 'users/userId/metadata');
  }

  const metadataRegex = /train\/create-with-meta\?characterImageLink=.*/;
  if (metadataRegex.test(path)) {
    return path.replace(
      metadataRegex,
      'train/create-with-meta?characterImageLink=characterImageLink'
    );
  }

  // Replace segments based on the defined placeholders
  return path.replace(/[A-Za-z0-9_-]+/g, match => {
    // Check if the segment is purely numeric
    if (/^\d+$/.test(match)) {
      return '{imageId}';
    }

    // Check if the segment starts with one of the known prefixes
    for (const prefix in placeholders) {
      if (match.startsWith(prefix)) {
        return placeholders[prefix];
      }
    }

    return match;
  });
};
const startAPICallTimedEvent = (path: string, method: string) => {
  const pathArray = path.split('/').filter(Boolean);
  const endpoint = pathArray[pathArray.length - 1];
  const rawPath = getPathWithPlaceholders(path);
  const newUUID = generateUUID();
  initiateTiming(
    {
      event: TimedTrackingEvents.apiCall,
      properties: {
        path: path,
        endpoint: endpoint,
        rawPath: rawPath,
        method: method,
      },
    },
    'CREATOR',
    newUUID
  );
  return newUUID;
};

const trackAPICallTimeEvent = (
  path: string,
  response: Response,
  method: string,
  newUUID: string
) => {
  const pathArray = path.split('/').filter(Boolean);
  const endpoint = pathArray[pathArray.length - 1];
  const rawPath = getPathWithPlaceholders(path);
  trackTimedEvent(
    {
      event: TimedTrackingEvents.apiCall,
      properties: {
        statusCode: response.status.toString(),
        path: path,
        endpoint: endpoint,
        rawPath: rawPath,
        method: method,
      },
    },
    'CREATOR',
    newUUID
  );
};

const trackAPICallWithoutResponseTimeEvent = (
  path: string,
  method: string,
  newUUID: string,
  statusCode: string
) => {
  const pathArray = path.split('/').filter(Boolean);
  const endpoint = pathArray[pathArray.length - 1];
  const rawPath = getPathWithPlaceholders(path);
  trackTimedEvent(
    {
      event: TimedTrackingEvents.apiCall,
      properties: {
        statusCode: statusCode,
        path: path,
        endpoint: endpoint,
        rawPath: rawPath,
        method: method,
      },
    },
    'CREATOR',
    newUUID
  );
};

class HttpClient {
  static countryCode: string | null = null;

  static async fetchCountryCode() {
    if (!this.countryCode) {
      try {
        const response = await fetch('https://dashtoon.ai/cdn-cgi/trace');
        const text = await response.text();
        const match = text.match(/loc=(\w+)/);
        if (match && match[1]) {
          this.countryCode = match[1].toUpperCase(); // Set country code in uppercase
        }
      } catch (error) {
        console.error('Error fetching country code:', error);
        // Handle error appropriately
      }
    }
  }
  static async uploadFile(
    path: string,
    file: File,
    onProgress: (progress: number) => void
  ): Promise<any> {
    return new Promise(async (resolve, reject) => {
      await this.fetchCountryCode(); // Ensure country code is fetched

      const idToken = auth.currentUser ? await auth.currentUser.getIdToken() : null;
      const newUUID = startAPICallTimedEvent(path, 'UPLOAD_FILE');

      const xhr = new XMLHttpRequest();

      // Handle the upload progress
      xhr.upload.onprogress = event => {
        if (event.lengthComputable) {
          const progress = (event.loaded / event.total) * 100;
          onProgress(progress);
        }
      };

      // Response received
      xhr.onload = () => {
        // trackAPICallTimeEvent(path, xhr.status, 'POST', newUUID);
        if (xhr.status >= 200 && xhr.status < 300) {
          trackAPICallWithoutResponseTimeEvent(path, 'UPLOAD_FILE', newUUID, xhr.status.toString());
          resolve(JSON.parse(xhr.responseText));
        } else {
          trackAPICallWithoutResponseTimeEvent(path, 'UPLOAD_FILE', newUUID, xhr.status.toString());
          reject(JSON.parse(xhr.responseText));
        }
      };

      // Handle network errors
      xhr.onerror = () => {
        trackAPICallWithoutResponseTimeEvent(path, 'POST', newUUID, xhr.status.toString());
        reject('Network error');
      };

      // Set up request
      xhr.open('POST', `${config.baseUrl}${path}`);

      // Set headers
      if (idToken) {
        xhr.setRequestHeader('Authorization', `Bearer ${idToken}`);
        xhr.setRequestHeader('x-tenant', 'studio');
        if (this.countryCode) {
          xhr.setRequestHeader('x-country', this.countryCode);
        }
      }
      // Note: Additional headers can be set here if needed

      // Create FormData and append file
      const formData = new FormData();
      formData.append('file', file);

      // Send request
      xhr.send(formData);
    });
  }
  static async post(
    path: string,
    body: any,
    additionalHeaders: {} = {},
    showErrorToast: boolean = true,
    useApiError: boolean = false
  ): Promise<any> {
    await this.fetchCountryCode(); // Ensure country code is fetched
    const idToken = auth.currentUser ? await auth.currentUser.getIdToken() : null;
    const newUUID = startAPICallTimedEvent(path, 'POST');
    const finalPath = `${config.baseUrl}${path}`;
    const response = await fetch(finalPath, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
        'x-tenant': 'studio',
        ...(this.countryCode ? { 'x-country': this.countryCode } : {}),
        ...(idToken ? { Authorization: `Bearer ${idToken}` } : {}),
        ...additionalHeaders,
      },
      body: JSON.stringify(body),
    });
    trackAPICallTimeEvent(path, response, 'POST', newUUID);
    if (response.ok) {
      return await response.json();
    } else {
      const message = (await response.json()) as { message: string; code: number };
      showErrorToast &&
        store.dispatch({
          type: ErrorActions.UPDATE_ERROR,
          payload: { error: message.message, errorCode: response.status },
        });
      trackEvent(
        {
          event: TrackingEvents.apiError,
          properties: {
            path: getPathWithPlaceholders(finalPath),
            status: response.status.toString(),
            error: message.message,
          },
        },
        'CREATOR'
      );
      if (useApiError) throw new APIError(message.message, response.status);
      else throw Error(message.message);
    }
  }

  static async postWithoutBody(
    path: string,
    additionalHeaders: {} = {},
    showErrorToast: boolean = true
  ): Promise<any> {
    await this.fetchCountryCode(); // Ensure country code is fetched
    const idToken = auth.currentUser ? await auth.currentUser.getIdToken() : null;
    const newUUID = startAPICallTimedEvent(path, 'POST');
    const finalPath = `${config.baseUrl}${path}`;
    const response = await fetch(finalPath, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
        'x-tenant': 'studio',
        ...(this.countryCode ? { 'x-country': this.countryCode } : {}),
        ...(idToken ? { Authorization: `Bearer ${idToken}` } : {}),
        ...additionalHeaders,
      },
    });
    trackAPICallTimeEvent(path, response, 'POST', newUUID);
    if (response.ok) {
      return await response.json();
    } else {
      const message = await response.json();
      showErrorToast &&
        store.dispatch({
          type: ErrorActions.UPDATE_ERROR,
          payload: { error: message.message, errorCode: response.status },
        });
      trackEvent(
        {
          event: TrackingEvents.apiError,
          properties: {
            path: getPathWithPlaceholders(finalPath),
            status: response.status.toString(),
            error: message.message,
          },
        },
        'CREATOR'
      );
      throw Error(message.message);
    }
  }

  static async get(
    path: string,
    additionalHeaders: {} = {},
    showErrorToast: boolean = true
  ): Promise<any> {
    await this.fetchCountryCode(); // Ensure country code is fetched
    const idToken = auth.currentUser ? await auth.currentUser.getIdToken() : null;
    const newUUID = startAPICallTimedEvent(path, 'GET');
    const finalPath = `${config.baseUrl}${path}`;
    const response = await fetch(finalPath, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
        'x-tenant': 'studio',
        ...(this.countryCode ? { 'x-country': this.countryCode } : {}),
        ...(idToken ? { Authorization: `Bearer ${idToken}` } : {}),
        ...additionalHeaders,
        Accept: 'application/json',
      },
    });
    trackAPICallTimeEvent(path, response, 'GET', newUUID);

    if (!response.ok) {
      const message = await response.json();
      showErrorToast &&
        store.dispatch({
          type: ErrorActions.UPDATE_ERROR,
          payload: { error: message.message, errorCode: response.status },
        });
      trackEvent(
        {
          event: TrackingEvents.apiError,
          properties: {
            path: getPathWithPlaceholders(finalPath),
            status: response.status.toString(),
            error: message.message,
          },
        },
        'CREATOR'
      );
      throw new Error(message.message);
    }
    if (response.status === 200) {
      return await response.json();
    }
    return response;
  }

  static async put(
    path: string,
    body: any,
    additionalHeaders: {} = {},
    showErrorToast: boolean = true
  ): Promise<any> {
    await this.fetchCountryCode(); // Ensure country code is fetched
    const idToken = auth.currentUser ? await auth.currentUser.getIdToken() : null;
    const newUUID = startAPICallTimedEvent(path, 'PUT');
    const finalPath = `${config.baseUrl}${path}`;
    const response = await fetch(finalPath, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
        'x-tenant': 'studio',
        ...(this.countryCode ? { 'x-country': this.countryCode } : {}),
        ...(idToken ? { Authorization: `Bearer ${idToken}` } : {}),
        ...additionalHeaders,
      },
      body: JSON.stringify(body),
    });
    trackAPICallTimeEvent(path, response, 'PUT', newUUID);
    if (response.ok) {
      return await response.json();
    } else {
      const message = await response.json();
      showErrorToast &&
        store.dispatch({
          type: ErrorActions.UPDATE_ERROR,
          payload: { error: message.message, errorCode: response.status },
        });
      trackEvent(
        {
          event: TrackingEvents.apiError,
          properties: {
            path: getPathWithPlaceholders(finalPath),
            status: response.status.toString(),
            error: message.message,
          },
        },
        'CREATOR'
      );
      throw Error(message.message);
    }
  }

  static async delete(
    path: string,
    body: any,
    additionalHeaders: {} = {},
    showErrorToast: boolean = true
  ): Promise<any> {
    await this.fetchCountryCode(); // Ensure country code is fetched
    const idToken = auth.currentUser ? await auth.currentUser.getIdToken() : null;
    const newUUID = startAPICallTimedEvent(path, 'DELETE');
    const finalPath = `${config.baseUrl}${path}`;
    const response = await fetch(finalPath, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
        'x-tenant': 'studio',
        ...(this.countryCode ? { 'x-country': this.countryCode } : {}),
        ...(idToken ? { Authorization: `Bearer ${idToken}` } : {}),
        ...additionalHeaders,
      },
      body: JSON.stringify(body),
    });
    trackAPICallTimeEvent(path, response, 'DELETE', newUUID);
    if (response.status === 200) {
      return await response.json();
    } else {
      const message = await response.json();
      showErrorToast &&
        store.dispatch({
          type: ErrorActions.UPDATE_ERROR,
          payload: { error: message.message, errorCode: response.status },
        });
      trackEvent(
        {
          event: TrackingEvents.apiError,
          properties: {
            path: getPathWithPlaceholders(finalPath),
            status: response.status.toString(),
            error: message.message,
          },
        },
        'CREATOR'
      );
      throw Error(message.message);
    }
  }

  static async patch(
    path: string,
    body: any,
    additionalHeaders: {} = {},
    showErrorToast: boolean = true
  ): Promise<any> {
    await this.fetchCountryCode(); // Ensure country code is fetched
    const idToken = auth.currentUser ? await auth.currentUser.getIdToken() : null;
    const newUUID = startAPICallTimedEvent(path, 'PATCH');
    const finalPath = `${config.baseUrl}${path}`;
    const response = await fetch(finalPath, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
        'x-tenant': 'studio',
        ...(this.countryCode ? { 'x-country': this.countryCode } : {}),
        ...(idToken ? { Authorization: `Bearer ${idToken}` } : {}),
        ...additionalHeaders,
      },
      body: JSON.stringify(body),
    });
    trackAPICallTimeEvent(path, response, 'PATCH', newUUID);
    if (response.status === 200) {
      return await response.json();
    } else {
      const message = await response.json();
      showErrorToast &&
        store.dispatch({
          type: ErrorActions.UPDATE_ERROR,
          payload: { error: message.message, errorCode: response.status },
        });
      trackEvent(
        {
          event: TrackingEvents.apiError,
          properties: {
            path: getPathWithPlaceholders(finalPath),
            status: response.status.toString(),
            error: message.message,
          },
        },
        'CREATOR'
      );
      throw Error(message.message);
    }
  }
}

export default HttpClient;
