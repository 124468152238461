import { PollImagesActions } from '../Actions/PollImages';
import { ImageGenerationState } from '../../Models/GenerateImage';
import { SaveImageResponse } from '../../Models/SaveImage';
import { SegmentationResult } from '../../Models/Segment';
import { MagicEraserPollingResult } from '../../Models/MagicErase';

export interface PendingImageState {
  generationId: string;
  frameId?: string;
  batchCount: number;
  time: number;
  useA100Client: boolean;
  lowPriorityQueue?: boolean;
  estimateGenerationTime: number;
}

export enum PreprocessorImageType {
  UPSCALE = 'UPSCALE',
  SEGMENT = 'SEGMENT',
  MAGIC_ERASE = 'MAGIC_ERASE',
}

export interface PendingPreprocessorImageState {
  generationId: string;
  frameId?: string;
  time: number;
  estimateGenerationTime: number;
  type: PreprocessorImageType;
}

export interface MultiInpaintPendingState {
  imageGenerationState: ImageGenerationState[];
  generationIds: string[];
}

export enum UpscalerStatus {
  SUCCESS = 'SUCCESS',
  FAILED = 'FAILED',
}

export interface UpscalerResult {
  generationId: string;
  savedImages?: SaveImageResponse;
  status: UpscalerStatus;
}

export interface PollImagesState {
  generationIds: string[];
  pendingImages: PendingImageState[];
  multiInpaintState?: MultiInpaintPendingState;
  pendingUpscalerImageGenerationIds: string[];
  upscalerResults?: UpscalerResult[];
  pendingPreprocessorImages: PendingPreprocessorImageState[];
  pendingSegmentationImageGenerationIds: string[];
  segmentationResults?: SegmentationResult[];
  backgroundRemovalSegmentResults?: SegmentationResult[];
  pendingMagicEraseImageGenerationIds: string[];
  magicEraseResults?: MagicEraserPollingResult[];
}

export const PollImagesInitialState: PollImagesState = {
  generationIds: [],
  pendingImages: [],
  pendingUpscalerImageGenerationIds: [],
  pendingPreprocessorImages: [],
  pendingSegmentationImageGenerationIds: [],
  segmentationResults: [],
  pendingMagicEraseImageGenerationIds: [],
  magicEraseResults: [],
};

export interface PollImageActionRequest {
  type: PollImagesActions;
  payload: any;
}

export const PollImageReducer = (
  state: PollImagesState = PollImagesInitialState,
  action: PollImageActionRequest
): PollImagesState => {
  switch (action.type) {
    case PollImagesActions.UPDATE_POLL_IMAGES:
      const ids = [...state.generationIds, ...action.payload] as string[];
      const set = Array.from(new Set(ids));
      return { ...state, generationIds: set };
    case PollImagesActions.REMOVE_POLL_IMAGE:
      return { ...state, generationIds: state.generationIds.filter(id => id !== action.payload) };
    case PollImagesActions.ADD_PENDING_IMAGE:
      return { ...state, pendingImages: [...state.pendingImages, action.payload] };
    case PollImagesActions.REMOVE_PENDING_IMAGE:
      return {
        ...state,
        pendingImages: state.pendingImages.filter(image => image.generationId !== action.payload),
      };
    case PollImagesActions.UPDATE_MULTI_INPAINT_PENDING_STATE:
      return { ...state, multiInpaintState: action.payload };
    case PollImagesActions.REMOVE_MULTI_INPAINT_PENDING_STATE:
      return { ...state, multiInpaintState: undefined };
    case PollImagesActions.ADD_PENDING_UPSCALER_IMAGE_GENERATION_ID:
      return {
        ...state,
        pendingUpscalerImageGenerationIds: [
          ...state.pendingUpscalerImageGenerationIds,
          action.payload,
        ],
      };
    case PollImagesActions.REMOVE_PENDING_UPSCALER_IMAGE_GENERATION_IDS:
      return {
        ...state,
        pendingUpscalerImageGenerationIds: state.pendingUpscalerImageGenerationIds.filter(
          id => !action.payload.includes(id)
        ),
      };
    case PollImagesActions.ADD_UPSCALER_POLL_RESULTS:
      return { ...state, upscalerResults: action.payload };
    case PollImagesActions.ADD_PENDING_PREPROCESSOR_IMAGES:
      return {
        ...state,
        pendingPreprocessorImages: [...state.pendingPreprocessorImages, action.payload],
      };
    case PollImagesActions.REMOVE_PENDING_PREPROCESSOR_IMAGES:
      return {
        ...state,
        pendingPreprocessorImages: state.pendingPreprocessorImages.filter(
          image => image.generationId !== action.payload
        ),
      };

    case PollImagesActions.ADD_PENDING_SEGMENTATION_IMAGES_GENERATION_ID:
      return {
        ...state,
        pendingSegmentationImageGenerationIds: [
          ...state.pendingSegmentationImageGenerationIds,
          action.payload,
        ],
      };
    case PollImagesActions.REMOVE_PENDING_SEGMENTATION_IMAGES_GENERATION_IDS: {
      return {
        ...state,
        pendingSegmentationImageGenerationIds: state.pendingSegmentationImageGenerationIds.filter(
          id => !action.payload.includes(id)
        ),
      };
    }
    case PollImagesActions.ADD_SEGMENTATION_POLL_RESULTS:
      return { ...state, segmentationResults: action.payload };
    case PollImagesActions.ADD_BACKGROUND_REMOVAL_SEGMENT_POLL_RESULTS:
      return { ...state, backgroundRemovalSegmentResults: action.payload };
    case PollImagesActions.ADD_PENDING_MAGIC_ERASE_IMAGES_GENERATION_ID:
      return {
        ...state,
        pendingMagicEraseImageGenerationIds: [
          ...state.pendingMagicEraseImageGenerationIds,
          action.payload,
        ],
      };
    case PollImagesActions.REMOVE_PENDING_MAGIC_ERASE_IMAGES_GENERATION_IDS:
      return {
        ...state,
        pendingMagicEraseImageGenerationIds: state.pendingMagicEraseImageGenerationIds.filter(
          id => !action.payload.includes(id)
        ),
      };
    case PollImagesActions.ADD_MAGIC_ERASE_POLL_RESULTS:
      return { ...state, magicEraseResults: action.payload };

    default:
      return state;
  }
};
