import { AnyAction } from 'redux';
import { SubscriptionPlans, UserQuotaResponse, UserSubscriptionPlanEnum } from '../../Models/User';
import { UserQuotaSubscriptionActions } from '../Actions/UserQuotaSubscription';

export interface SubscriptionState {
  data: SubscriptionPlans;
  loading: boolean;
  error: string | null;
}

export interface QuotaState {
  data: UserQuotaResponse;
  loading: boolean;
  error: string | null;
}

export const initialSubscriptionState: SubscriptionState = {
  data: {
    plans: [],
    subscribedPlans: [],
  },
  loading: false,
  error: null,
};

export const SubscriptionReducer = (
  state = initialSubscriptionState,
  action: AnyAction
): SubscriptionState => {
  switch (action.type) {
    case UserQuotaSubscriptionActions.FETCH_SUBSCRIPTION_DETAILS_REQUEST:
      return { ...state, loading: true, error: null };
    case UserQuotaSubscriptionActions.FETCH_SUBSCRIPTION_DETAILS_SUCCESS:
      return { ...state, loading: false, data: action.payload };
    case UserQuotaSubscriptionActions.FETCH_SUBSCRIPTION_DETAILS_FAILURE:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

export const initialQuotaState: QuotaState = {
  data: {
    characters: {
      quotaLeft: -1,
      timeLeftForReset: 0,
    },
    generations: {
      quotaLeft: -1,
      timeLeftForReset: 0,
    },
    currentPlan: UserSubscriptionPlanEnum.BASIC,
    walletBalance: -1,
  },
  loading: true,
  error: null,
};

export const QuotaReducer = (state = initialQuotaState, action: AnyAction): QuotaState => {
  switch (action.type) {
    case UserQuotaSubscriptionActions.FETCH_USER_QUOTAS_REQUEST:
      return { ...state, loading: true, error: null };
    case UserQuotaSubscriptionActions.FETCH_USER_QUOTAS_SUCCESS:
      return { ...state, loading: false, data: action.payload };
    case UserQuotaSubscriptionActions.FETCH_USER_QUOTAS_FAILURE:
      return { ...state, loading: false, error: action.payload };
    case UserQuotaSubscriptionActions.UPDATE_CREDITS_BALANCE:
      return { ...state, data: { ...state.data, walletBalance: action.payload } };
    default:
      return state;
  }
};
