import { SavedImagesState } from '../Redux/Reducers/SavedImages';

export enum SavedImageMetadataTypes {
  CHARACTER_WEIGHT = 'CHARACTER_WEIGHT',
  STYLE_WEIGHT = 'STYLE_WEIGHT',
  IMAGE_GENERATION_TYPE = 'IMAGE_GENERATION_TYPE',
  INPAINT_TYPE = 'INPAINT_TYPE',
  INPAINT_FILL_TYPE = 'INPAINT_FILL_TYPE',
  DENOISE_STRENGTH = 'DENOISE_STRENGTH',
  IS_UPLOADED = 'IS_UPLOADED',
  TEXTUAL_EMBEDDINGS = 'TEXTUAL_EMBEDDINGS',
  EDIT_TYPE = 'EDIT_TYPE',
  ENABLE_CLIP_SKIP = 'ENABLE_CLIP_SKIP',
  ENABLE_HIGH_RES_FIX = 'ENABLE_HIGH_RES_FIX',
  HIGH_RES_FIX_STRENGTH = 'HIGH_RES_FIX_STRENGTH',
  INIT_IMAGE = 'INIT_IMAGE',
  MASK_IMAGE = 'MASK_IMAGE',
  NSFW_DETECTED = 'NSFW_DETECTED',
  NSFW_SCORE = 'NSFW_SCORE',
}

export interface SavedImageMetadata {
  name: SavedImageMetadataTypes;
  value: string;
}

export interface SaveImageRequest {
  generationId: string;
  frameId?: string;
  showId: string;
  savedBy: string;
  imageURL: string;
  prompt: string;
  negativePrompt: string;
  batchCount: number;
  cfgScale: number;
  steps: number;
  seed: number;
  imageWidth: number;
  imageHeight: number;
  style: string;
  subStyle: string;
  character: string;
  controlNet: string;
  isFavourite: boolean;
  controlnetDetails: ControlNetDetails[];
  rating: Rating;
  metadata: SavedImageMetadata[];
  episodeId: string;
}

export interface SaveImageResponse {
  generationId: string;
  frameId?: string;
  id: number;
  showId: string;
  savedBy: string;
  imageURL: string;
  prompt: string;
  negativePrompt: string;
  batchCount: number;
  cfgScale: number;
  steps: number;
  seed: number;
  imageWidth: number;
  imageHeight: number;
  style: string;
  subStyle: string;
  character: string;
  controlNet: string;
  createdAt: Date;
  isFavourite: boolean;
  controlnetDetails: ControlNetDetails[];
  rating: Rating;
  metadata: SavedImageMetadata[];
  episodeId: string;
  isPinned?: boolean;
  isDownloaded?: boolean;
  type?: SaveImageType;
}

export const SavedImagesInitialState: SavedImagesState = {
  savedImages: [],
};

export interface ControlNetDetails {
  preprocessor: string;
  model: string;
  weight: number;
  initImage?: string;
  processedImage?: string;
}

export enum Rating {
  UNRATED = 'UNRATED',
  POOR = 'POOR',
  OK = 'OK',
  GREAT = 'GREAT',
}

export enum SavedImageEditType {
  ERASED = 'ERASED',
  UPSCALED = 'UPSCALED',
  MULTI_INPAINT = 'MULTI_INPAINT',
}

export interface EditedSavedImageRequest {
  images: string[];
  existingGenerationId: string;
  type: string;
  existingImageId?: number;
  showId: string;
  prompt?: string;
  episodeId: string;
}

export enum SaveImageType {
  UPLOADED = 'UPLOADED',
  MASK = 'MASK',
  CHARACTER_THUMBNAIL_RESULT = 'CHARACTER_THUMBNAIL_RESULT',
  TXT2IMG_RESULT = 'TXT2IMG_RESULT',
  IMG2IMG_RESULT = 'IMG2IMG_RESULT',
  INPAINT_RESULT = 'INPAINT_RESULT',
  CONTROLNET_RESULT = 'CONTROLNET_RESULT',
  MAGIC_ERASE_RESULT = 'MAGIC_ERASE_RESULT',
  UPSCALE_RESULT = 'UPSCALE_RESULT',
  CREATIVE_UPSCALE_RESULT = 'CREATIVE_UPSCALE_RESULT',
  COMPOSE_RESULT = 'COMPOSE_RESULT',
  CROP_RESULT = 'CROP_RESULT',
  COLOR_RESULT = 'COLOR_RESULT',
  DRAWING_RESULT = 'DRAWING_RESULT',
  FRAME_RESULT = 'FRAME_RESULT',
  LIVE_RESULT = 'LIVE_RESULT',
  LIVE_INPAINT_RESULT = 'LIVE_INPAINT_RESULT',
  SEGMENTATION_RESULT = 'SEGMENTATION_RESULT',
  BACKGROUND_REMOVE_RESULT = 'BACKGROUND_REMOVE_RESULT',
  CHARACTER_QC_RESULT = 'CHARACTER_QC_RESULT',
  OTHER = 'OTHER',
  DALLE_RESULT = 'DALLE_RESULT',
}

export enum ImageOperation {
  INIT_IMAGE = 'INIT_IMAGE',
  MASK_IMAGE = 'MASK_IMAGE',
  CONTROLNET = 'CONTROLNET',
}

export interface SaveImageBase64Request {
  initImageId?: SavedImagesInitImages[];
  base64Image: string;
  contentType?: string;
  fileExtension?: string;
  type: SaveImageType;
  showId: string;
  episodeId: string;
  frameId?: string;
}

export interface SaveLiveImageRequest {
  initImageId?: SavedImagesInitImages[];
  base64Image: string;
  contentType?: string;
  fileExtension?: string;
  type: SaveImageType;
  showId: string;
  episodeId: string;
  frameId?: string;
  prompt?: string;
  negativePrompt?: string;
  batchCount?: number;
  cfgScale?: number;
  steps?: number;
  seed?: number;
  imageWidth?: number;
  imageHeight?: number;
  style?: string;
  subStyle?: string;
  character?: string;
  controlNet?: string;
  createdAt?: Date;
  generationId?: string;
  strength?: number;
  allowNsfw?: boolean;
}

// extend SaveLiveImageRequestWithTime
export interface SaveLiveImageRequestWithTime extends SaveLiveImageRequest {
  startTime?: number;
  inferenceStartTime?: number;
  inferenceEndTime?: number;
}

export interface SavedImagesInitImages {
  id: number;
  generationType: ImageOperation;
}

export interface DownloadImageRequest {
  imageURL: string;
  contentType: string;
  fileExtension: string;
}

export interface DownloadImageResponse {
  imageURL: string;
}

export interface AutoInpaintRequest {
  image: string;
  loraList: string[];
}

export interface AutoInpaintResponse {
  masks_url_list: string;
}
