import { ReduxState } from '../Redux/ReduxInterface';
import { defaultWeight } from '../Constants/StyleConstants';
import { TextualEmbeddingDTO } from './TextualEmbedding';
import { ControlNetType, T2IAdapter } from './ControlNet';
import { SavedImageMetadata, SavedImagesInitImages, SaveImageResponse } from './SaveImage';

export declare interface Crop {
  x: number;
  y: number;
  width: number;
  height: number;
  unit: 'px' | '%';
}

export enum ImageGenerationStyle {
  NONE = '',
  DASH_NEGATIVE = 'dash_negative',
  SECO = 'seco',
  AMERICAN_COMIC_1 = 'american_comic_1',
}

export const getImageURLForStyle = (style: string): string => {
  switch (ImageGenerationStyle[style as keyof typeof ImageGenerationStyle]) {
    case ImageGenerationStyle.AMERICAN_COMIC_1.valueOf():
      return 'https://content.dashtoon.ai/imported-images/733260bf-dca4-46f3-9e61-478b68c8723e.png';
    case ImageGenerationStyle.SECO.valueOf():
      return 'https://content.dashtoon.ai/imported-images/863a632c-26e7-482c-a7b9-cdd22e29e696.png';
    case ImageGenerationStyle.DASH_NEGATIVE.valueOf():
      return 'https://content.dashtoon.ai/imported-images/095d0b0a-0619-4929-b70e-28d068dcd882.png';
    default:
      return '';
  }
};

export enum InpaintType {
  ONLY_MASKED = 'only_masked',
  WHOLE_IMAGE = 'whole_image',
}

export enum EditType {
  INPAINT_EDIT = 'inpaint',
  INSTRUCT_PIX2PIX_EDIT = 'edit',
}

export enum InpaintFillType {
  ORIGINAL = 'original',
  FILL = 'fill',
  LATENT_NOISE = 'latent_noise',
}

export enum InpaintFillTypeV2 {
  ORIGINAL = 'original',
  FILL = 'fill',
}

export enum GenerateImageType {
  TXT2IMG = 'txt2img',
  IMG2IMG = 'img2img',
  INPAINT = 'inpaint',
  MULTI_INPAINT = 'multi-inpaint',
}

export enum TranslateTextLanguage {
  ENGLISH = 'ENGLISH',
  JAPANESE = 'JAPANESE',
  KOREAN = 'KOREAN',
  CHINESE = 'CHINESE',
  CHINESE_TRADITIONAL = 'CHINESE_TRADITIONAL',
}

export const getImageGenerationState = (
  generateImageType: GenerateImageType,
  state: ReduxState
) => {
  switch (generateImageType) {
    case GenerateImageType.TXT2IMG:
      return state.text2ImageGeneration;
    case GenerateImageType.IMG2IMG:
      return state.image2ImageGeneration;
    case GenerateImageType.INPAINT:
      return state.inpaintGeneration;
  }
};

export interface ControlNetState {
  isEnabled: boolean;
  name?: string;
  image?: SaveImageResponse;
  base64Image?: String;
  imageFile?: string;
  weight?: number;
  model?: string;
  preprocessorModel?: string;
  allowFaceAlign: boolean;
  annotation?: SaveImageResponse;
  cropBounds?: Crop;
  isCropped?: boolean;
}

export interface T2IAdapterState {
  isEnabled: boolean;
  adapter?: T2IAdapter;
  image?: SaveImageResponse;
  base64Image?: String;
  weight?: number;
}

export const getInitialControlNetState = (controlnetType?: string): ControlNetState => {
  return {
    isEnabled: false,
    weight: 1,
    name: controlnetType ?? undefined,
    model: controlnetType ?? undefined,
    allowFaceAlign: false,
  };
};

export const getInitialT2IState = (adapter?: T2IAdapter): T2IAdapterState => {
  return {
    isEnabled: false,
    adapter: adapter,
    weight: 1,
  };
};

export interface ColorContext {
  r: number;
  g: number;
  b: number;
  prompt: string;
}

export interface ImageGenerationState {
  type: GenerateImageType;
  isGenerating: boolean;
  prompt: string;
  prevPrompt: string;
  negativePrompt: string;
  editType: EditType;
  seed: number;
  steps: number;
  cfg: number;
  batchSize: number;
  width: number;
  height: number;
  style: string;
  characterName: string;
  characterId: string;
  autoFixFace: boolean;
  controlNet: Array<ControlNetState>;
  controlNetPreprocessorImages: string[];
  initImage?: SaveImageResponse;
  initImageCropBounds?: Crop;
  maskImage?: string;
  greenMaskImage?: string;
  combinedMaskImage?: string;
  multiInpaintState?: ImageGenerationState[];
  selectedCharacterPrompt: string;
  strength?: number;
  liveStrength?: number;
  inpaintType?: InpaintType;
  inpaintFillType?: InpaintFillType;
  imageSegment?: string;
  colorContext?: ColorContext[];
  characterWeight: number;
  styleWeight: number;
  allowStyleWeight?: boolean;
  defaultStyleWeight?: number;
  textualEmbeddings: TextualEmbeddingDTO[];
  enableDiffusersInpaint?: boolean;
  color?: string;
  enableClipSkip?: boolean;
  enableHighResFix?: boolean;
  highResFixStrength?: number;
  t2iAdapters: T2IAdapter[];
  t2iAdaptersSelected: boolean;
}

export const getInitialImageGenerationState = (
  type: GenerateImageType,
  stopProp?: boolean
): ImageGenerationState => {
  return {
    type: type,
    batchSize: 4,
    cfg: 7,
    characterId: '',
    characterName: '',
    controlNet: [
      getInitialControlNetState(ControlNetType.SCRIBBLE_ADVANCED.toString()),
      getInitialControlNetState(ControlNetType.OPENPOSE.toString()),
      getInitialControlNetState(ControlNetType.EXPRESSION.toString()),
    ],
    controlNetPreprocessorImages: ['', '', ''],
    height: 512,
    negativePrompt: '',
    prompt: '',
    prevPrompt: '',
    editType: EditType.INPAINT_EDIT,
    seed: -1,
    steps: 20,
    style: '',
    width: 512,
    isGenerating: false,
    autoFixFace: false,
    selectedCharacterPrompt: '',
    strength: 0.5,
    liveStrength: 0.8,
    inpaintType: InpaintType.ONLY_MASKED,
    inpaintFillType: InpaintFillType.ORIGINAL,
    characterWeight: 0.65,
    styleWeight: defaultWeight,
    textualEmbeddings: [],
    initImage: undefined,
    initImageCropBounds: undefined,
    maskImage: undefined,
    greenMaskImage: undefined,
    combinedMaskImage: undefined,
    imageSegment: undefined,
    colorContext: undefined,
    enableDiffusersInpaint: undefined,
    multiInpaintState:
      !stopProp && type === GenerateImageType.INPAINT
        ? [getInitialImageGenerationState(GenerateImageType.INPAINT, true)]
        : undefined,
    color: undefined,
    t2iAdapters: [],
    t2iAdaptersSelected: false,
  };
};

export interface KeyValues {
  name: string;
  value: number;
}

export interface ControlNetRequest {
  name: string;
  image: SavedImagesInitImages;
  initImage?: SavedImagesInitImages;
  params: KeyValues[];
}

export interface SelectedT2IAdapter {
  name: string;
  image: SavedImagesInitImages;
  initImage: SavedImagesInitImages;
  params: KeyValues[];
}

export interface Text2ImgRequest {
  prompt: string;
  negativePrompt: string;
  autoFixFace: boolean;
  batchCount: number;
  cfg: number;
  characterId: string;
  characterName: string;
  controlNet: ControlNetRequest[];
  height: number;
  seed: number;
  selectedStyle: string;
  steps: number;
  style: string;
  useControlNet: boolean;
  width: number;
  characterWeight?: number;
  styleWeight?: number;
  nsfwFilter?: boolean;
  textualEmbeddings?: TextualEmbeddingDTO[];
  showId?: string;
  savedImageMetadata?: SavedImageMetadata[];
  frameId?: string;
  useA100Client: boolean;
  enableClipSkip?: boolean;
  enableHighResFix?: boolean;
  highResFixStrength?: number;
  lowPriority?: boolean;
  episodeId: string;
  useT2IAdapter: boolean;
  selectedT2IAdapters: SelectedT2IAdapter[];
  turboMode?: boolean;
  photoMakerMode?: boolean;
  charRefImage?: string[];
  enhancedPromptAdherence?: boolean;
  promptLanguage?: TranslateTextLanguage;
}

export interface Img2ImgRequest {
  autoFixFace: boolean;
  batchCount: number;
  cfg: number;
  characterId: string;
  characterName: string;
  controlNet: ControlNetRequest[];
  height: number;
  imagePrompt: string;
  imagesData: SavedImagesInitImages[];
  negativePrompt: string;
  seed: number;
  selectedStyle: string;
  steps: number;
  strength: number;
  style: string;
  useControlNet: boolean;
  width: number;
  characterWeight?: number;
  styleWeight?: number;
  nsfwFilter?: boolean;
  textualEmbeddings?: TextualEmbeddingDTO[];
  showId?: string;
  savedImageMetadata?: SavedImageMetadata[];
  frameId?: string;
  useA100Client: boolean;
  lowPriority?: boolean;
  episodeId: string;
  useT2IAdapter: boolean;
  selectedT2IAdapters: SelectedT2IAdapter[];
  turboMode?: boolean;
  photoMakerMode?: boolean;
  charRefImage?: string[];
  promptLanguage?: TranslateTextLanguage;
}

export interface PaintWithWordsRequest {
  autoFixFace: boolean;
  batchCount: number;
  cfg: number;
  characterId: string;
  characterName: string;
  controlNet: ControlNetRequest[];
  height: number;
  imagePrompt: string;
  negativePrompt: string;
  seed: number;
  selectedStyle: string;
  steps: number;
  strength: number;
  style: string;
  useControlNet: boolean;
  width: number;
  segmentationImages: string[];
  colorContext: ColorContext[];
  characterWeight?: number;
  styleWeight?: number;
  nsfwFilter?: boolean;
  textualEmbeddings?: TextualEmbeddingDTO[];
  showId?: string;
  savedImageMetadata?: SavedImageMetadata[];
  frameId?: string;
  useA100Client: boolean;
  lowPriority?: boolean;
  episodeId: string;
  turboMode?: boolean;
}

export interface InpaintRequest {
  autoFixFace: boolean;
  batchCount: number;
  cfg: number;
  characterId: string;
  characterName: string;
  controlNet: ControlNetRequest[];
  height: number;
  imagePrompt: string;
  imagesData: SavedImagesInitImages[];
  inpaintFillType: InpaintFillType;
  inpaintType: InpaintType;
  mask: string;
  negativePrompt: string;
  editType: EditType;
  seed: number;
  selectedStyle: string;
  steps: number;
  strength: number;
  style: string;
  useControlNet: boolean;
  width: number;
  characterWeight?: number;
  styleWeight?: number;
  nsfwFilter?: boolean;
  textualEmbeddings?: TextualEmbeddingDTO[];
  diffusersInpaint?: boolean;
  showId?: string;
  savedImageMetadata?: SavedImageMetadata[];
  frameId?: string;
  useA100Client: boolean;
  lowPriority?: boolean;
  episodeId: string;
  turboMode?: boolean;
  photoMakerMode?: boolean;
  enableFoocusInpaint?: boolean;
  promptLanguage?: TranslateTextLanguage;
}

export interface InstructPix2PixRequest {
  autoFixFace: boolean;
  batchCount: number;
  cfg: number;
  characterId: string;
  characterName: string;
  controlNet: ControlNetRequest[];
  height: number;
  imagePrompt: string;
  imagesData: SavedImagesInitImages[];
  inpaintFillType: InpaintFillType;
  inpaintType: InpaintType;
  mask: string;
  negativePrompt: string;
  editType: EditType;
  seed: number;
  selectedStyle: string;
  steps: number;
  strength: number;
  style: string;
  useControlNet: boolean;
  width: number;
  characterWeight?: number;
  styleWeight?: number;
  nsfwFilter?: boolean;
  textualEmbeddings?: TextualEmbeddingDTO[];
  showId?: string;
  savedImageMetadata?: SavedImageMetadata[];
  frameId?: string;
  useA100Client: boolean;
  lowPriority?: boolean;
  episodeId: string;
  turboMode?: boolean;
}

export interface PreprocessorRequest {
  image: SavedImagesInitImages;
  name: string;
  params: KeyValues[];
  baseImage: string;
  faceAlign: boolean;
  showId: string;
  episodeId: string;
  useT2IAdapter: boolean;
}

export interface ImageGenerationResponse {
  generationIds: string[];
  walletBalance: number;
  estimatedTime: number;
  isLowPriority: boolean;
}
