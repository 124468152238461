import HttpClient from '../../Utils/HttpClient';
import {
  episodeApiPrefix,
  readerApiPrefix,
  showApiPrefix,
  studioApiPrefix,
} from '../../Constants/Constants';
import {
  Episode,
  EpisodeDTO,
  EpisodeUpdateDTO,
  EpisodeWithThumbnailDTO,
} from '../../Models/Episode';
import { Show } from '../../Models/Show';
import useFetch from '../../Hooks/useFetch';
import httpClient from '../../Utils/HttpClient';

export const fetchCreateEpisodeData = async (episodeDTO: EpisodeDTO): Promise<Episode> => {
  try {
    return HttpClient.post(`${studioApiPrefix}${episodeApiPrefix}`, episodeDTO, {
      resourceId: episodeDTO.showId,
    });
  } catch (error) {
    console.error(`fetchCreateEpisodeData failed with error: ${error}`);
    throw error;
  }
};

export const fetchAllEpisodes = async (showId: string, email: string): Promise<Show[]> => {
  try {
    return HttpClient.get(
      `${studioApiPrefix}${showApiPrefix}/myShows?offset=0&count=50&includeEpisodes=true`
    );
  } catch (error) {
    console.error(`fetchAllEpisodes failed with error: ${error}`);
    throw error;
  }
};

export const updateEpisode = async (episode: EpisodeDTO, episodeId: string): Promise<Episode> => {
  try {
    return HttpClient.put(`${studioApiPrefix}${episodeApiPrefix}/id/${episodeId}`, episode, {
      resourceId: episode.showId,
    });
  } catch (error) {
    console.error(`updateEpisode failed with error: ${error}`);
    throw error;
  }
};

export const fetchViewsCountForEpisode = async (episodeIds: string[]) => {
  try {
    return httpClient.post(`${readerApiPrefix}/episode/views`, episodeIds);
  } catch (error) {
    console.error(`Unable to fetch views count for episodes`);
    return null;
  }
};
export const sendEpisodeUpdateData = async (
  episodeData: EpisodeWithThumbnailDTO,
  episodeId: string,
  showId: string
): Promise<Episode> => {
  try {
    return HttpClient.put(
      `${studioApiPrefix}${episodeApiPrefix}/id/${episodeId}/update`,
      episodeData,
      {
        resourceId: showId,
      }
    );
  } catch (error) {
    console.error(`update Episode with thummbnail and state failed with error: ${error}`);
    throw error;
  }
};

export const updateEpisodeThumbnail = async (
  showId: string,
  episodeUpdateRequest: EpisodeUpdateDTO,
  silent = false
) => {
  try {
    return HttpClient.put(
      `${studioApiPrefix}${episodeApiPrefix}`,
      episodeUpdateRequest,
      {
        resourceId: showId,
      },
      !silent
    );
  } catch (error) {
    console.error(`Failed to update episode thumbnail: ${error}`);
    throw error;
  }
};

export const getEpisodeData = async (episodeId: string) => {
  try {
    return HttpClient.get(`${studioApiPrefix}${episodeApiPrefix}/${episodeId}`);
  } catch (error) {
    console.error(`Failed to get episode data: ${error}`);
    throw error;
  }
};
