export enum StoryCrafterStateActionV2 {
  SET_STORY_CHUNKS = 'SET_STORY_CHUNKS',
  SET_EPISODE_CHUNKS = 'SET_EPISODE_CHUNKS',
  SET_ENTITIES = 'SET_ENTITIES',
  SET_BLOCKS = 'SET_BLOCKS',
  SET_NEW_BLOCKS = 'SET_NEW_BLOCKS',
  ADD_TO_UPDATED_BLOCKS = 'ADD_TO_UPDATED_BLOCKS',
  REMOVE_FROM_UPDATED_BLOCKS = 'REMOVE_FROM_UPDATED_BLOCKS',
  ADD_TO_UPDATED_DIALOGS = 'ADD_TO_UPDATED_DIALOGS',
  SET_ASSOCIATIONS = 'SET_ASSOCIATIONS',
  SET_SHAPE_ASSOCIATION_MAP = 'SET_SHAPE_ASSOCIATION_MAP',
  SET_CHARACTER_EXTRACTION_LOADING = 'SET_CHARACTER_EXTRACTION_LOADING',
  SET_SCREENPLAY_LOADING = 'SET_SCREENPLAY_LOADING',
  SET_CHARACTER_MODAL_OPEN = 'SET_CHARACTER_MODAL_OPEN',
  SET_ADD_PANELS_FROM_SCRATCH_LOADING = 'SET_ADD_PANELS_FROM_SCRATCH_LOADING',
  SET_ADD_PANELS_LOADING = 'SET_ADD_PANELS_LOADING',
  SET_DELETING_ENTITY = 'SET_DELETING_ENTITY',
  SET_EXPAND_ALL = 'SET_EXPAND_ALL',
  RESET_STORYCRAFTER_STATE = 'RESET_STORYCRAFTER_STATE',
  SET_STORY_TYPE = 'SET_STORY_TYPE',
  SET_STORYCRAFTER_STORY = 'SET_STORYCRAFTER_STORY',
  SET_STORYCRAFTER_SELECTED_OPTION = 'SET_STORYCRAFTER_SELECTED_OPTION',
  SET_STORYCRAFTER_STORY_LOADING = 'SET_STORYCRAFTER_STORY_LOADING',
  SET_STORYCRAFTER_STORY_ERROR = 'SET_STORYCRAFTER_STORY_ERROR',
  SET_STORYCRAFTER_LAST_BLOCK_SEEN = 'SET_STORYCRAFTER_LAST_BLOCK_SEEN',
  SET_STORYCRAFTER_LAST_IMAGE_GENERATED = 'SET_STORYCRAFTER_LAST_IMAGE_GENERATED',
  SET_FETCHING_CHUNKS = 'SET_FETCHING_CHUNKS',
  SET_BULK_EDITING_STATE = 'SET_BULK_EDITING_STATE',
}

export interface StoryCrafterStateV2ActionRequest {
  type: StoryCrafterStateActionV2;
  payload: any;
}
